import React from "react";
import Layout from "components/layout.component";
import { useConfig } from "hooks/use-config.hook";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { translationHelper } from "helpers/translation.helper";
import { useTranslation } from "react-i18next";

const PrivacyPolicyPage: React.FC = (props) => {
    const { i18n } = useTranslation();
    const config = useConfig();

    const { organizer } = config;

    const pdfd = config.organizer.personal_data_file_description_info;

    if (i18n.language !== "fi") {
        return <Layout>
            <div className="prose bg-white overflow-hidden shadow sm:rounded-md bg-white w-full max-w-full">
                <div className="px-4 py-4 sm:px-6">
                    <h1>This page is currently available only in Finnish. Please see the Finnish version of the
                        page.</h1>
                </div>
            </div>
        </Layout>;
    }

    return (
        <Layout>
            <GatsbySeo noindex={true} />
            <div className="prose bg-white overflow-hidden shadow sm:rounded-md bg-white w-full max-w-full">
                <div className="px-4 py-4 sm:px-6">
                    <h1>Tietosuojaseloste</h1>

                    <p>
                        Tässä tietosuojaselosteessa kerromme, miten{" "}
                        {config.organizer.name} ("Rekisterinpitäjä")
                        yhteistyössä Eventio Oy:n ("Tietojen käsittelijä" tai
                        "Eventio") käsittelevät asiakkaidensa ja
                        verkkopalveluidensa käyttäjien ("Rekisteröity")
                        henkilötietoja ja miten henkilötietojen käsittelyyn voi
                        vaikuttaa. Rekisterinpitäjä ja Tietojen käsittelijä
                        noudattavat toiminnassaan soveltuvaa
                        tietosuojalainsäädäntöä.
                    </p>

                    {pdfd?.custom_url && (
                        <p>
                            Tämä tietosuojaseloste täydentää Rekisterinpitäjän
                            tietosuojaselostetta, joka on nähtävissä osoitteessa
                            <a
                                href={translationHelper(
                                    pdfd?.custom_url,
                                    i18n.language
                                )}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {pdfd?.custom_url}
                            </a>
                            .
                        </p>
                    )}

                    <h2>Rekisterinpitäjä</h2>

                    <p>
                        {organizer.name} ({organizer.business_id})<br />
                        {organizer.address && (
                            <div>
                                {organizer.address?.address_line1}
                                <br />
                                {organizer.address.address_line2 &&
                                    organizer.address?.address_line2 && <br />}
                                {organizer.address?.postal_code}
                                <br />
                                {organizer.address?.city}
                                <br />
                                {organizer.address?.country}
                                <br />
                            </div>
                        )}
                    </p>

                    {organizer.customer_service_info && (
                        <div>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: translationHelper(
                                        organizer.customer_service_info,
                                        i18n.language
                                    ),
                                }}
                            />
                        </div>
                    )}

                    <h2>Tietojen käsittelijä</h2>

                    <p>
                        Eventio Oy (Y-tunnus 1925337-4)
                        <br />
                        Juristinkatu 6<br />
                        20780 Kaarina
                        <br />
                        puh. <a href="tel:+358403110565">0403 110 565</a>
                        <br />
                        e-mail{" "}
                        <a href="mailto:asiakaspalvelu@eventio.fi">
                            asiakaspalvelu@eventio.fi
                        </a>
                    </p>

                    <h2>Kerättävät henkilötiedot</h2>

                    <p>
                        Rekisterinpitäjä ja Eventio käsittelevät henkilötietoja
                        vain niiltä osin, kuin se on tarpeellista tässä
                        tietosuojaselosteessa kuvattujen käyttötarkoitusten
                        toteuttamiseksi. Kerättävät henkilötiedot ja niiden
                        käsittelyn laajuus vaihtelevat Rekisterinpitäjän ja
                        Rekisteröidyn välisestä suhteesta, annetuista luvista
                        sekä Rekisteröidyn käyttämän selaimen
                        yksityisyysasetuksista.
                    </p>

                    <h3>Asiakas- ja osallistujatiedot</h3>

                    <p>
                        Tiedot kerätään Rekisteröidyltä hänen tehdessään
                        ostoksia tai varauksia verkkopalvelussa,
                        ilmoittautuessaan tapahtumaan tai rekisteröityessään
                        verkkopalveluun tai liittyessään esimerkiksi
                        markkinointilistalle. Alla mainituista
                        henkilötietoryhmistä kerätään kulloinkin tarpeelliset
                        tiedot. Osa tiedoista voi olla vapaaehtoisia.
                    </p>

                    <ul>
                        <li>perustiedot, kuten nimi</li>
                        <li>
                            yhteystiedot, kuten postiosoite, sähköpostiosoite,
                            puhelinnumero
                        </li>
                        <li>
                            tarkennetut henkilötiedot, kuten syntymäaika,
                            sukupuoli, kotikunta, ammatti
                        </li>
                        <li>
                            lupa- ja kieltotiedot, kuten markkinointilupa tai
                            tietojen julkaisukielto
                        </li>
                        <li>
                            tapahtumaosallistumiseen liittyvät tarkentavat
                            tiedot, kuten osallistujaryhmät, taustatiedot tai
                            tapahtumaosallistumiseen liittyvät valinnat
                        </li>
                        <li>
                            asiakaskorttien, esimerkiksi jäsenkorttien tai
                            kanta-asiakaskorttien tunnisteet ja
                            voimassaolotiedot
                        </li>
                        <li>
                            tunnistamistiedot, kuten salatut käyttäjätunnus- ja
                            salasanatiedot tai muut vastaavat tunnisteet
                        </li>
                        <li>
                            mahdolliset Rekisteröidyn antamat muut lisätiedot
                        </li>
                    </ul>

                    <p>
                        Tietyissä tilanteissa tiedot voi antaa Rekisteröidyn
                        sijaan hänen valtuuttamansa henkilö, esimerkiksi ryhmän
                        yhteyshenkilö.
                    </p>

                    <h3>Tilaustiedot</h3>

                    <p>
                        Tilaustietoja kerätään Rekisteröidyn tehdessä ostoksia,
                        varauksia tai ilmoittautumisia verkkopalvelussa tai
                        sähköpostitse, puhelimitse tai henkilökohtaisesti.
                    </p>

                    <ul>
                        <li>
                            tilauksen lähdetiedot, esimerkiksi millä tavoin
                            tilaus on tehty
                        </li>
                        <li>
                            toivottu maksu- ja toimitustapa sekä tiedot
                            suoritetusta maksusta
                        </li>
                        <li>
                            tilauksen sisältö ja tuotteet sekä niihin tehdyt
                            muutokset ja peruutukset
                        </li>
                        <li>
                            tilauksen käsittelyyn liittyvät muut lisätiedot,
                            kuten laskutustiedot tai käsittelyhistoria
                        </li>
                    </ul>

                    <h3>Tapahtumavierailuun liittyvät tiedot</h3>

                    <p>
                        Vierailuun liittyviä tietoja voidaan kerätä
                        Rekisteröidyn vieraillessa tapahtumassa tai sen jälkeen.
                        Kerättäviä tietoja ovat esimerkiksi:
                    </p>

                    <ul>
                        <li>
                            vierailuaikaan liittyvät tiedot, kuten saapumis- ja
                            lähtöaika tapahtumasta
                        </li>
                        <li>
                            liikkumiseen liittyvät tiedot, kuten
                            lipuntarkastuspiste tai vierailut eri
                            palvelupisteissä
                        </li>
                        <li>
                            asiakaspalvelun kehittämiseen liittyvät tiedot,
                            kuten tapahtuman aikana annettu palaute
                        </li>
                    </ul>

                    <h3>Verkkopalveluiden käyttötiedot</h3>

                    <p>
                        Verkkopalveluiden käytöstä kerätään tietoja
                        Rekisteröidyn käyttäessä Evention Rekisterinpitäjälle
                        tuottamaa verkkopalvelua. Kerättäviä tietoja ovat
                        esimerkiksi:
                    </p>

                    <ul>
                        <li>
                            käytettävästä laitteesta tai sovelluksesta
                            kerättävät tiedot, kuten selainversio, laitetyyppi,
                            ruudun koko ja ip-osoite
                        </li>
                        <li>
                            tiedot verkkopalveluiden käytöstä, kuten tiedot
                            sivulatauksista, palvelussa vietetystä ajasta ja
                            palvelussa liikkumisesta
                        </li>
                    </ul>

                    <p>
                        Verkkopalveluiden käyttäjät voidaan tunnistaa
                        esimerkiksi yhteisöliitännäisten tai sähköpostiviestissä
                        olevan tunnisteen perusteella.
                    </p>

                    <h2>
                        Henkilötietojen käyttötarkoitukset ja käsittelyn
                        oikeusperusta
                    </h2>

                    <p>
                        Rekisterinpitäjä käyttää kerättyjä henkilötietoja
                        erilaisiin asiakassuhteen hoitamisen kannalta
                        välttämättömiin toimenpiteisiin, kuten tapahtuman
                        järjestämiseen asianmukaisesti ja turvallisesti,
                        tilattujen tuotteiden tai palveluiden toimittamiseen
                        sekä asiakaspalvelun ja muun asiakastuen tarjoamiseen.
                        Kerättyjä tietoja voidaan lisäksi käyttää
                        asiakasviestintään ja asiakassuhteen ylläpitoon.
                        Henkilötietojen käsittely perustuu Rekisteröidyn ja
                        Rekisterinpitäjän väliseen sopimukseen tuotteen tai
                        palvelun toimittamisesta (kuten lippu- tai
                        oheistuotetilaus) ja oikeutettuun etuun käsitellä
                        tietoja asiakas- tai työntekijäsuhteen muodostavan
                        toimenpiteen seurauksena (kuten ilmoittautumisesta
                        tapahtumaan).
                    </p>

                    <p>
                        Rekisterinpitäjä voi käyttää kerättyjä henkilötietoja
                        markkinointiin ja mainontaan ja muihin kaupallisiin
                        toimenpiteisiin, mikäli Rekisteröity on siihen antanut
                        luvan. Henkilötietojen käsittely kaupallisiin
                        käyttötarkoituksiin perustuu sähköisen
                        suoramarkkinoinnin osalta Rekisteröidyn suostumukseen.
                    </p>

                    <p>
                        Rekisterinpitäjä voi käyttää kerättyjä henkilötietoja
                        tuotteiden ja palveluiden kehittämiseen sekä
                        palvelutarjonnan parantamiseen. Kehittämiseen ja
                        parantamiseen liittyviä toimenpiteitä ovat esimerkiksi
                        tuotesuositukset tai viestinnän personointi. Tietojen
                        käsittely perustuu tällöin Rekisterinpitäjän
                        oikeutettuun etuun hyödyntää kerättyjä tietoja
                        Rekisteröidyn hyväksi.
                    </p>

                    <h2>Henkilötietojen jakaminen ja luovuttaminen</h2>

                    <p>
                        Rekisterinpitäjä ja Eventio ovat sopineet keskenään
                        henkilötietojen käsittelystä tämän tietosuojaselosteen
                        ja soveltuvan tietosuojalainsäädännön kuvaamalla
                        tavalla. Eventio toimii tietojen käsittelijänä ja vain
                        Rekisterinpitäjän lukuun. Eventio ei luovuta kolmannelle
                        osapuolelle, ellei Rekisterinpitäjä näin erikseen
                        kirjallisesti ohjeista. Poikkeuksena tästä on jotkut
                        verkkopalveluiden käyttöön liittyvät tiedot, joiden
                        luovuttamisen verkkopalveluiden käyttäjä voi halutessaan
                        estää Evästekäytännössä kuvatuilla tavoilla.
                    </p>

                    <p>
                        Evention lisäksi Rekisterinpitäjä saattaa käyttää muita
                        kolmansien osapuolten palveluita henkilötietojen
                        käsittelyyn. Tällaisissa tapauksissa Rekisterinpitäjä
                        varmistaa henkilötietojen lainmukaisen käsittelyn
                        sopimusjärjestelyin sekä kolmannelle osapuolelle
                        annettavin kirjallisin ohjeistuksin.
                    </p>

                    <p>
                        Lisäksi Rekisterinpitäjä tai Eventio voivat luovuttaa
                        henkilötietoja kolmansille osapuolille, mikäli soveltuva
                        lainsäädäntö näin velvoittaa tai jos se on välttämätöntä
                        Rekisterinpitäjän, Evention tai Rekisteröidyn oikeuksien
                        tai turvallisuuden toteuttamiseksi.
                    </p>

                    <h3>
                        Henkilötietojen siirtäminen EU/ETA-alueen ulkopuolelle
                    </h3>

                    <p>
                        Rekisterinpitäjä tai Eventio eivät pääsääntöisesti
                        siirrä henkilötietoja EU/ETA-alueen ulkopuolelle, eikä
                        henkilötietoja muutoinkaan käsitellä EU/ETA-alueen
                        ulkopuolella. Mikäli tietoja poikkeuksellisesti
                        siirretään EU/ETA-alueen ulkopuolelle, tietoja
                        käsitellään tämän tietosuojaselosteen ja lainsäädännön
                        edellyttämällä tavalla.
                    </p>

                    <h3>Evästeiden käyttö</h3>

                    <p>
                        Verkkopalveluidemme käyttökokemuksen parantamiseksi,
                        sekä käytön seuraamiseksi ja helpottamiseksi käytämme
                        evästeitä (cookies). Evästeet mahdollistavat lyhyiden
                        tekstimuotoisten tietojen tallentamisen käyttäjän
                        selaimeen myöhempää käyttöä varten.
                    </p>

                    <p>
                        Lisätietoa evästekäytännöistä ja kolmansien osapuolten
                        asettamista evästeistä on saatavilla{" "}
                        <a
                            href={`${process.env.GATSBY_WEBSITE_URL}/cookie-policy`}
                        >
                            Evästekäytännöstä
                        </a>
                        .
                    </p>

                    <h2>Sijaintitietojen kerääminen ja käyttö</h2>

                    <p>
                        Rekisterinpitäjä tai Eventio eivät pääsääntöisesti kerää
                        tai käytä Rekisteröityjen tarkkoja sijaintitietoja.
                    </p>

                    <p>
                        Verkkopalveluita käytettäessä tallennetaan kuitenkin
                        käyttäjän ip-osoite, joka voidaan yhdistää
                        maantieteelliseen sijaintiin yleensä kuntatasolla.
                        Lisäksi esimerkiksi tapahtumanaikaisten palveluiden
                        käytön yhteydessä tallennetaan tietoja, jotka voidaan
                        yhdistää tiettyyn sijaintiin, kuten lipuntarkastuksessa
                        tiettyyn tapahtumapaikan sisäänkäyntiin.
                    </p>

                    <h2>Henkilötietojen säilyttäminen</h2>

                    <p>
                        Rekisterinpitäjä tai Eventio säilyttävät henkilötietoja
                        niin kauan, kuin on tarpeellista tietojen
                        käyttötarkoituksen toteuttamiseksi. Voimassa oleva
                        kirjanpito- tai muu pakottava lainsäädäntö voi kuitenkin
                        velvoittaa Rekisterinpitäjän tai Evention säilyttämään
                        tietoja tätä pidempään. Tällaisissa tilanteissa
                        noudatetaan lainsäädännön määrittämiä säilytysaikoja.
                    </p>

                    <p>
                        Verkkopalveluiden käytöstä kertyviä tietoja säilytetään
                        noin 12 kuukauden ajan sellaisessa muodossa, joista
                        yksittäinen käyttäjä on tunnistettavissa.
                    </p>

                    <p>
                        Rekisteröityjen perustiedot, yhteystiedot, lupa- ja
                        kieltotiedot, tarkennetut henkilötiedot sekä yleisesti
                        tieto Rekisteröidyn osallistumisesta tiettyyn
                        tapahtumaan tai tieto Rekisteröidyn tekemästä
                        tilauksesta säilytetään pääsääntöisesti 36 kuukautta
                        siitä, kun Rekisteröity on asioinut Rekisterinpitäjän
                        tai Evention kanssa.
                    </p>

                    <p>
                        Tiettyyn tapahtumaan liittyvät yksityiskohtaiset
                        Rekisteröityyn liittyvät osallistuja-,
                        ilmoittautumistiedot säilytetään pääsääntöisesti 36
                        kuukautta tapahtuman päättymisen jälkeen.
                    </p>

                    <p>
                        Tilauksiin liittyvät tiedot säilytetään pääsääntöisesti
                        36 kuukautta tapahtuman päättymisen jälkeen tai jos
                        tilaus ei liity tiettyyn tapahtumaan, tilausajankohdan
                        jälkeen. Tiedot peruutetuista tilauksista säilytetään
                        pääsääntöisesti 36 kuukautta tapahtuman tai
                        peruutusajankohdan jälkeen.
                    </p>

                    <p>
                        Maksutapahtumiin liittyvät tiedot, kuten kuittikopiot,
                        säilytetään lainsäädännön vaatimusten mukaisesti.
                    </p>

                    {pdfd?.retention && (
                        <div className="account_custom">
                            <p>
                                Edellä mainittujen lisäksi Rekisterinpitäjä
                                noudattaa seuraavia tietojen säilytysaikoja:
                            </p>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: translationHelper(
                                        pdfd.retention,
                                        i18n.language
                                    ),
                                }}
                            />
                        </div>
                    )}

                    <h2>Rekisteröidyn oikeudet ja vaikuttamismahdollisuudet</h2>

                    <p>
                        Rekisteröidyllä on oikeus saada pääsy omiin
                        henkilötietoihinsa sekä oikeus tarkistaa ja korjata
                        itseään koskevia henkilötietoja. Lisäksi Rekisteröidyllä
                        on oikeus pyytää itseään koskevien henkilötietojen
                        poistamista niiltä osin, kuin se on muun lainsäädännön
                        puitteissa mahdollista. Rekisteröidyllä on oikeus myös
                        siirtää itseään koskevat henkilötiedot toiselle
                        Rekisterinpitäjälle.
                    </p>

                    <p>
                        Rekisteröidyllä on oikeus kieltää suoramarkkinointi sekä
                        vastustaa henkilötietojensa käsittelyä
                        suoramarkkinointitarkoituksiin.
                    </p>

                    <p>
                        Näiden oikeuksien käyttöä koskevat pyynnöt tulee esittää
                        Rekisterinpitäjälle tässä tietosuojaselosteessa
                        annettujen yhteystietojen mukaisesti.
                    </p>

                    <h2>Tietoturva</h2>

                    <p>
                        Rekisterinpitäjä ja Eventio huolehtivat henkilötietojen
                        tietoturvallisesta käsittelystä asianmukaisin fyysisin
                        ja teknisin tietoturvatoimenpitein suojataksemme tietoja
                        katoamiselta, tuhoutumiselta, väärinkäytöksiltä ja
                        oikeudettomalta käytöltä ja luovutukselta.
                        Rekisterinpitäjä ja Eventio pyrkivät tietoturvalliseen
                        henkilötietojen käsittelyyn esimerkiksi rajaamalla
                        pääsyn henkilötietoihin ja varmistamalla, että
                        työntekijät ja alihankkijat käyttävät henkilötietoja
                        annettujen ohjeiden, sopimusten ja lainsäädännön
                        mukaisesti.
                    </p>
                </div>
            </div>
        </Layout>
    );
};

export default PrivacyPolicyPage;
